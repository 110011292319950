@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

body {
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  height: 100%;
  background-color: white;
  ::-webkit-scrollbar {
    display: none;
  };
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  height: 100%;
}

input:focus {
  outline: none;
}

.ReactModal__Overlay {
  opacity: 1;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 1;
}

.str-chat {
  --str-chat__font-family: 'Poppins', sans-serif;
}

#securityCodeSecureField_container {
  height: 50px; /* Ajusta la altura según tus necesidades */
  border: 0.5px solid #9ca3af;
  border-radius: 7px;
  padding-left: 10px;
  padding-top: 4px;
}

.PhoneInputInput{
  background-color: #f3f4f6;
}